/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var vh = window.innerHeight;
          document.documentElement.style.setProperty('--vh', vh+'px');
        // vh var fix for mobile devices
        window.addEventListener('resize', function(){
          var vh = window.innerHeight;
          document.documentElement.style.setProperty('--vh', vh+'px');
        });

        $('.submenu .submenu li a').each(function(){
          var img = $(this).data('img');
          var description = $(this).data('description');

          if(description) {
            $(this).append('<span>'+description+'</span>');
          }
          if(img) {
            $(this).parent().append('<div class="link-image"><img src="'+img+'"></div>');
          }
        });

        $('li.copy-parent-link').each(function(){
          var newLi = $(this);
          var $parentLinkElement = $(this).parent().parent();
          if($parentLinkElement.length) {
            var $parentLink = $parentLinkElement.children('a');
            newLi.html($parentLink.clone());
            newLi.attr('class',$parentLinkElement.attr('class'));
            newLi.removeClass('menu-item-has-children has-submenu');
            newLi.addClass('menu-item-extended-info ontdek-link');
            var parentText = $parentLink.text();

            var link = newLi.find('a');
            if(link){
              link.text('Alles over '+ parentText.toLowerCase());
              link.attr('href',link.data('href'));
              link.removeClass('submenu-toggle');
            }
          }
        });

        function bannerHeightOffset(){
          var bannerHeight = $('.banner.is-active').outerHeight();
          if($('#wpadminbar').length && $('.banner.is-active').parent('body').length){
            bannerHeight += $('#wpadminbar').outerHeight();
          }
          if($(window).scrollTop() < bannerHeight ){
            bannerHeight -= $(window).scrollTop();
          }
          
          $('.banner .menu-toggle-content, .banner .nav-primary, .banner .nav-primary-overlay, .banner .nav-primary ul[role="menu"].submenu').css('top', bannerHeight);
          $('.banner .nav-primary ul[role="menu"].submenu').css('height', 'calc(var(--vh) - '+bannerHeight+'px)');
          if($('.banner .nav-primary').css('position') === 'fixed'){
            $('.banner .nav-primary').css('height', 'calc(var(--vh) - '+bannerHeight+'px)');
          }
        }
        bannerHeightOffset();

        function openSubmenu(element) {
          element.parent().parent('.submenu').addClass('active');
          element.parent().parent('.submenu').prev().addClass('active');
      
          element.addClass('active');
          bannerHeightOffset();
          element.next('.submenu').addClass('active');

          element.closest('header.banner').find('.close-sub-menu').addClass('active');
          element.closest('header.banner').find('.menu-toggle-content').addClass('is-active');
          $('body').addClass('scroll-lock');
        }

        function closeSubmenu(elem) {
          var $submenu = elem.closest('.submenu');
          if($submenu.length) {
              $submenu.find('.submenu-toggle.active,.submenu.active,.close-sub-menu.active').removeClass('active');
          }else {
            $('.submenu-toggle.active, .submenu.active, .close-sub-menu.active').removeClass('active');
            if(!elem.closest('header.banner').find('.menu-toggle').is(':visible')){
              $('.menu-toggle-content').removeClass('is-active');
              $('body').removeClass('scroll-lock');
            }
          }
        }

        $('.submenu-toggle').click(function(e){
          e.preventDefault();
          bannerHeightOffset();
          
          if(!$(this).hasClass('active')){
            closeSubmenu($(this));
            openSubmenu($(this));
          }else {
            closeSubmenu($(this));
          }
        });

        $('.submenu-up').click(function() {
          $(this).closest('.submenu').prev().trigger('click');
        });
        
        // JavaScript to be fired on all pages
        $('.menu-toggle').click(function(){
          bannerHeightOffset();
          $('.hamburger', this).toggleClass('is-active');
          $(this).parent().next('.menu-toggle-content').toggleClass('is-active');
          $('body').toggleClass('scroll-lock');
        });

        $('.main-menu-overlay, .nav-primary-overlay').click(function(e){
          closeSubmenu($(this));
          $('.menu-toggle-content').removeClass('is-active');
          $('body').removeClass('scroll-lock');
          $('.main-menu').removeClass('active');
          $('header.banner').find('.hamburger').removeClass('is-active');
        });

         $('.header-slider').slick({
            arrows: false,
            dots: false,
            slidesToShow: 1,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 6000,
            cssEase: 'ease-in-out'
         });  

        $('.huisstijl-slider').slick({
          arrows: false,
          dots: false,
          slidesToShow: 1,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 0,
          speed: 3000,
          centerMode: false,
          variableWidth: true,
          cssEase: "linear"
        });

        $( '.slider' ).each( function() {
          var $container = $( this ).closest('.slider-container');
          var isGallery = $(this).hasClass('photo-slider'); 

          if(isGallery) {
            $(this).slick({
              arrows: true,
              dots: true,
              infinite: false,
              centerMode: true,
              centerPadding: '0',
              prevArrow: '<div class="slider-arrow slider-arrow-prev"><i class="fa-solid fa-angle-left"></i></div>',
              nextArrow: '<div class="slider-arrow slider-arrow-next"><i class="fa-solid fa-angle-right"></i></div>',
              appendArrows: $container.find('.slider-dots-container'),
              appendDots: $container.find('.slider-dots-container')
            });
          }else {
            $(this).slick({
              arrows: true,
              dots: true,
              slidesToShow: 1,
              infinite: false,
              prevArrow: '<div class="slider-arrow slider-arrow-prev"><i class="fa-solid fa-angle-left"></i></div>',
              nextArrow: '<div class="slider-arrow slider-arrow-next"><i class="fa-solid fa-angle-right"></i></div>',
              appendArrows: $container.find('.slider-dots-container'),
              appendDots: $container.find('.slider-dots-container'),
              asNavFor: $container.find('.slider-navigation')
            });
            $container.find('.slider-navigation').slick({
              slidesToShow: 4,
              infinite: false,
              dots: false,
              arrows: false,
              asNavFor: $container.find('.slider'),
              variableWidth: true,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    vertical: true,
                    variableWidth: false
                  }
                }
              ]
            });
          }
        });


        $('.slider-navigation a').on('click', function (e) {
          e.preventDefault();
          $( this ).closest('.slider-container').find('.slider').slick('slickGoTo',$(this).data("index"));
        });
        
        var $lastSectionClass = $('main.main > section:last-child').attr('class');
        if($lastSectionClass){
          if($lastSectionClass.includes('is-style-light') || $lastSectionClass.includes('is-style-half-white-to-light')){
            $('footer').find('section:first-child').addClass('connect-to-last-section');
          }
        }

        var currentPage = 1;
        $('#load-more').on('click', function() {
          currentPage++;
          var posttype = $(this).data('posttype');
          var postQuery = $(this).data('json') ? $(this).data('json') : '';
          $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            dataType: 'json',
            data: {
              action: 'load_more_posts',
              paged: currentPage,
              posttype: posttype,
              postquery: postQuery
            },
            success: function (res) {
              if(currentPage >= res.max) {
                $('#load-more').hide();
              }
              $('.infinite-scroll-container').append(res.html);
            }
          });
        });

        function resetFeaturedImage($hoverElement) {
          var $featuredImage = $hoverElement.closest('.featured-image-container').find('.image-container.featured-image');
          var imageStyle = $hoverElement.closest('.row').find('.col-12:first-child .image-container').css('background-image');
          $featuredImage.css('background-image',imageStyle);
        }

        function setFeaturedImage($hoverElement) {
          var $featuredImage = $hoverElement.closest('.featured-image-container').find('.image-container.featured-image');
          var imageStyle = $hoverElement.find('.image-container').css('background-image');
          $featuredImage.css('background-image',imageStyle);
        }

        $('.featured-image-container .item-card').hover(
          function(){setFeaturedImage($(this));},
          function(){/*resetFeaturedImage($(this));*/}
        );
        
        $('form#calculator').find('input').on('keyup',function(e){calcPowerOrCurrent(e);});
        $('form#calculator').find('select').on('change',function(e){calcPowerOrCurrent(e);});

        function calcPowerOrCurrent(e) {
          var $input = $(e.target);
          
          var powerfactor = 0.8,
              wortel3 = 1.73205;

          var stroom = document.getElementById("stroom"),
              kw = document.getElementById("kw"),
              kva = document.getElementById("kva"),
              voltage = document.getElementById("voltage");
          
          var voltagevalue = $(voltage).val(),
              kwvalue = 0,
              kvavalue = 0;

          switch ($input.attr('id')) {
            case 'stroom':

              kwvalue = voltagevalue * powerfactor * stroom.value * wortel3 / 1000;
              kw.value = Math.round(kwvalue);

              break;

            case 'kw':

              kwvalue = kw.value*1000 / (voltagevalue * powerfactor * wortel3);
              stroom.value = Math.round(kwvalue*10)/10;

              break;

            default:

              kw.value = '';
              stroom.value = '';
              kva.value = '';
              return;
          }

          kvavalue = kwvalue / 0.8;
          kva.value = Math.round(kvavalue);
          
        }

        $('.slider-tijdlijn-tijd').each(function(index) {
          $(this).slick({
            arrows: false,
            speed: 500,
            centerMode: true,
            centerPadding: '20%',
            slidesToShow: 3,
            focusOnSelect: true,
            infinite: false,
            asNavFor: '.slider-tijdlijn, .slider-tijdlijn-content',
            responsive: [
              {
                breakpoint: 900,
                settings: {
                  centerPadding: '10%',
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  centerPadding: '0',
                  slidesToShow: 3,
                }
              }
            ]
          });
        });

        $('.slider-tijdlijn').each(function(index) {
          $(this).slick({
            arrows: true,
            speed: 500,
            prevArrow: '<div class="slick-arrow arrow-left"><button class="btn btn-primary btn-icoon btn-square"><i class="fas fa-chevron-left"></i></button></div>',
            nextArrow: '<div class="slick-arrow arrow-right"><button class="btn btn-primary btn-icoon btn-square"><i class="fas fa-chevron-right"></i></button></div>',
            centerMode: true,
            centerPadding: '0',
            slidesToShow: 1,
            infinite: false,
            asNavFor: '.slider-tijdlijn-tijd, .slider-tijdlijn-content',
            responsive: [
              {
                breakpoint: 600,
                settings: {
                  centerPadding: '5%',
                  slidesToShow: 1,
                }
              }
            ]
          });
        });

        $('.slider-tijdlijn-content').each(function(index) {
          $(this).slick({
            arrows: false,
            speed: 500,
            swipe: false,
            centerMode: true,
            centerPadding: '0',
            slidesToShow: 1,
            infinite: false,
            asNavFor: '.slider-tijdlijn-tijd, .slider-tijdlijn',
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Hide Header on on scroll down
        var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var navbarHeight = $('.banner').outerHeight();

        $(window).scroll(function(event){
            didScroll = true;
        });

        function hasScrolled() {
            var st = $(this).scrollTop();
            
            // Make sure they scroll more than delta
            if(Math.abs(lastScrollTop - st) <= delta) {
              return;
            }

            $footerBanner = $('footer .banner');
            $headerBanner = $('body > header.banner');
            
            if(st > navbarHeight) {
              $footerBanner.show().addClass('is-active');
              $headerBanner.removeClass('is-active');
            }else {
              $footerBanner.hide().removeClass('is-active');
              $headerBanner.addClass('is-active');
            }
            
            lastScrollTop = st;
        }

        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.